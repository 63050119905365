document.addEventListener('DOMContentLoaded', function(){

    function RequestFromBlog(settings) {
        
        this.settings = {
            baseURL: helpers.hoisted.themeURL,
            postsURL: '/blog/wp-json/wp/v2/posts',
            mediaURL: '/blog/wp-json/wp/v2/media/',
            per_page: 3,
            debug: false,
            logPosts: true,
        }
        
        this.init();
        
    }
    
    RequestFromBlog.prototype.init = function () {
        ''
        // log settings for manual checking
        if (this.settings.debug) {
            this.logSettings();        
        }
        
        // log returned values
        if ( this.settings.logPosts ) {
            var postsToLog = this.getPosts();
            postsToLog.complete(function(r){
                console.log(r.responseJSON); 
            }) 
        }
        
        // this.outputPosts();
    }
    
    RequestFromBlog.prototype.logSettings = function () {
        console.log(this.settings);
    }
    
    RequestFromBlog.prototype.makeAjaxCall = function (method, data, url) {
        return $.ajax({
            type: method,
            url: url,
            data: data,
        });
    }
    
    RequestFromBlog.prototype.getPosts = function () {
        var s = this.settings;
        var posts = this.makeAjaxCall('GET', '', s.postsURL + '?per_page=' + s.per_page);
        return posts;
    }
    
    RequestFromBlog.prototype.outputPosts = function () {
        var posts = this.getPosts();
        var s = this.settings;

        posts.complete(function (r) {
            var fetchedPosts = r.responseJSON
            var status = r.status;
        var postHTML = '';
        
        if (status == 200) {
            
            for (var i = 0; i < fetchedPosts.length; i++) {
                var postName = fetchedPosts[i].title.rendered,
                postExcerpt = fetchedPosts[i].excerpt.rendered,
                postLink = fetchedPosts[i].link,
                postID = fetchedPosts[i].id,
                featuredImage = fetchedPosts[i].fimg_url !== undefined ? fetchedPosts[i].fimg_url : '//placehold.it/170x170';
                
                console.log(fetchedPosts[i]);
                
                postHTML += '<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">';
                postHTML += '<article>';
                
                postHTML += '<a href="' + postLink + '"><img src="' + featuredImage + '" alt="' + postName + '"></a>';
                postHTML += '<a href="' + postLink + '"><h3 class="section-subtitle">' + postName + '</h3></a>';
                postHTML += postExcerpt;
                
                postHTML += '</article>';
                postHTML += '</div>'; // col-xs-12 col-sm-12 col-md-6 col-lg-6
                
            }
            
        }
        
        $('.receive-posts').append(postHTML);
        
    });
    
    posts.fail(function () {
        $('.receive-posts').append('<p class="text-center mt-1 mb-1">Houve um problema na conexão com o nosso blog. Por favor atualize a página ou clique <a href="/blog/" target="_BLANK">AQUI</a> para acessar diretamente</p>');
    });
    
    }
});


