
function init() {

    // start slick carousel instances
    instanceCarousels();

    // defer iframe loads
    deferIframeLoads();

    // google map
    handleGoogleMap();

    // instance field masks
    maskInstancing();

    // handle all form submits
    $('body').on('submit', 'form:not(".searchform")', formHandling);

    // handles state field on contact page
    $('#estado').on('change', fieldHandling);

    // populates field with states
    populateStates('#estado');

    // toggles mobile menu
    if ( helpers.isMobile ) {
        $('.hamburger').on('click', toggleMenu);
        $('.menu-item-has-children').on('click', openSubMenu);
        $('.close-submenu').on('click', closeSubMenu);
    }

    // click to show
    clickToShow('.mobile-show-video', '.iframe-body', true);

    // filters
    if ( $('.promotion-body').length ) {
        var filter = new Filters;
    }

    // overlay form
    $('body').on('click', '.open-overlay' , openOverlay);
    $('.close-overlay, .close-ead').on('click', closeOverlay);
    $('.close-black-friday').on('click', closeOverlay);


    // phone cta
    openPhoneCta();
    closeModalCta();
    printNumbers();
    $('.form-pickers').on('click', formPickers);
    $('.close-cta').on('click', closeCTA);
    $('.phone-icon').on('click', reopenCTA);

    // Inner page anchors
    $('.page-anchors a').on('click', anchorSmoothScroll);
    $(window).on('scroll', makeAnchorsFixed);
    makeAnchorsFixed();

    // Load more promotions
    $('.load-more-items').on('click', loadPromotions);

    // modal black friday
    // blackFridayModal();

    // Modal 18 anos
    // modal18()

    // modal ead
    // showEadModal();
    // $('.ead').on('click', function(e) {
    //     e.preventDefault();
    //     helpers.lockBody();
    //     $('.ead-overlay').fadeIn();
    // })
}

// window load binds
window.onload = init;

function DOMLoaded() {
    // these are not always necessary but sometimes they fuck with ya
    if (helpers.iOS) {
        document.querySelector('html').classList.add('ios');
    } else if (helpers.IE()) {
        document.querySelector('html').classList.add('ie');
    }
}

// domcontent binds
document.addEventListener('DOMContentLoaded', DOMLoaded);