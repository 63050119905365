// unique scripts go here.

/**
 *
 * @method instanceCarousels
 * @uses slick-carousel
 * @see http://kenwheeler.github.io/slick/
 *
 * carousel instancing
 *
*/


function instanceCarousels() {

	// banner da home

	$('.hero .banners').slick({
		slidesToShow: 1,
		arrows: false,
		dots: true,
		autoplay: true,
		autoplaySpeed: 4500,
		pauseOnFocus: false,
		pauseOnHover: false,
	});

	// Depoimentos

	$('.testimonial-slides').slick({
		slidesToShow: 3,
		prevArrow: $('.square-small.prev'),
		nextArrow: $('.square-small.next'),
	});

	// Galeria das páginas internas

	$('.inner-gallery-carousel').slick({
		slidesToShow: 1,
		fade: true,
		responsive: [{
			breakpoint: 997,
			settings: {
				dots: true,
			}
		}]
	});

	// mobile genérico

	if (helpers.isMobile) {
		var slickObj = {
			slidesToShow: 1,
			fade: true,
			autoplay: true,
			autoplaySpeed: 4000,
			arrows: false,
			adaptiveHeight: true,
		}

		var selector = $('.becomes-carousel-on-mobile');

		if (selector.hasClass('has-dots')) {
			slickObj.dots = true;
		}

		selector.slick(slickObj);
	}
}

/**
 *
 * @method deferIframeLoads
 *
 * Make iframes load after full page load for better performance
 *
*/

function deferIframeLoads() {
	$('iframe').each(function () {
		$(this).prop('src', $(this).data('src'));
	});
}

/**
 *
 * @method handleGoogleMap
 * @uses GoogleMapsApi
 * @see https://developers.google.com/maps/documentation/javascript/tutorial
 * @see file includes/functions/toolbox.php
 *
 * map options handling
 *
*/

function handleGoogleMap(element) {
	if (arguments.length < 1) {
		if (document.getElementById('map-body') !== undefined) {
			element = document.getElementById('map-body');
		}
	}

	if (document.getElementById('map-body') !== null) {

		// main map variables
		var lat = parseFloat(helpers.hoisted.mapLat);
		var lng = parseFloat(helpers.hoisted.mapLng);

		// main map
		var map = new google.maps.Map(element, {
			center: new google.maps.LatLng(lat, lng),
			zoom: 5,
			mapTypeId: google.maps.MapTypeId.ROADMAP
		});

		// wp_localize_script, toolbox.php
		var markerLocations = helpers.hoisted.children !== undefined ? helpers.hoisted.children : false;

		// marker
		var marker;

		if (markerLocations) {
			for (var i = 0; i < markerLocations.length; i++) {

				var child = markerLocations[i];
				var childLat = parseFloat(child.lat);
				var childLng = parseFloat(child.lng);
				var childName = child.name;

				marker = new google.maps.Marker({
					position: new google.maps.LatLng(childLat, childLng),
					map: map,
					title: childName,
					icon: helpers.hoisted.themeURL + '/images/icons/map-marker.png'
				});

			}
		}
	} else if (document.getElementById('map') !== null) {
		var mapElement = document.getElementById('map');
		var map = new google.maps.Map(mapElement, {
			center: new google.maps.LatLng(-23.557797, -46.661580),
			zoom: 18,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
		});

		var markerAbout = new google.maps.Marker({
			position: new google.maps.LatLng(-23.557797, -46.661580),
			title: 'Visite nosso escritório!',
			icon: helpers.hoisted.themeURL + '/images/icons/map-marker.png',
			map: map
		});
	}
}

//clear all inputs at page
function clearForm() {
	$(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
	$(':checkbox, :radio').prop('checked', false);
}

/**
 * @method formHandling
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
 * @param evt Event handler
 *
*/

function formHandling(evt) {
	evt.preventDefault();
	var formData = $(this).serializeObject();
	var redirect;
	var phone = $(this).find('input[name="Telefone"], input[name="Celular"], input[name="Telefone Fixo"]');
	var phoneVal = phone.val();
	var phoneLength = phoneVal.length;
	var button = $(this).find('button');
	var oldTxt = button.text();
	button.addClass('btn-disabled loading');


	if (phoneLength < 14) {
		Swal.fire(
			'Ops!',
			'Por favor preencha um número de telefone válido.',
			'error'
		);
		phone.focus();
		return false;
	}

	formData.url_atual = window.location.href;

	objToSubmit = {
		action: 'mundi_submit_emails',
		values: formData
	}

	$.ajax({
		type: 'POST',
		url: helpers.hoisted.adminURL,
		data: objToSubmit,
		success: function (response) {
			Swal.fire(
				'Obrigado!',
				'Seu E-mail foi enviado com sucesso! <br> Nossos analistas retornarão o mais breve possível.',
				'success'
			)

			ga("gtm1.send", "event", "Formulário", "Envio", "Formulários de Contato");

			clearForm();

			$('.swal2-confirm').on('click', function () {
				$('.close-modal').trigger('click');
				$('.close-overlay').trigger('click');
			});

		},
		fail: function () {
			Swal.fire(
				'Ops!',
				'Houve um problema com o envio do seu E-mail. <br> Por favor tente novamente mais tarde ou entre em contato através de um dos nossos telefones.',
				'error'
			)
		},
		always: function () {
			button.text(oldText).removeClass('btn-disabled loading')
		}
	});
}

/**
 * @method fieldHandling
 *
 * Gets city list from endpoint based on chosen state
 *
*/

function fieldHandling() {
	var toEnable = $('.disabled-at-start');
	var enabledField = $('#cidade');
	var cityEndpoint = 'https://gist.githubusercontent.com/letanure/3012978/raw/36fc21d9e2fc45c078e0e0e07cce3c81965db8f9/estados-cidades.json';
	var cityRes = $.get(cityEndpoint);
	var chosenState = $(this).val();
	var cities;

	cityRes.done(function (res) {
		var parsed = JSON.parse(res);
		parsed = parsed.estados;


		parsed.map(function (obj) {
			if (obj.nome == chosenState) {
				cities = obj.cidades;
			}
		});


		enabledField.find('option').remove();

		for (var i = 0; i < cities.length; i++) {
			enabledField.append('<option value="' + cities[i] + '">' + cities[i] + '</option>');
		}

		// enable select
		toEnable.removeClass('disabled-at-start');
		enabledField.prop('disabled', false);

	});

}

/**
 *
 * @method populateStates
 * @param stateField string field to be filled
 *
 * fills fields with states after page load for better performance
 *
*/

function populateStates(stateField) {

	if (document.querySelector(stateField) === undefined) {
		return;
	}

	var stateEndpoint = 'https://gist.githubusercontent.com/letanure/3012978/raw/36fc21d9e2fc45c078e0e0e07cce3c81965db8f9/estados-cidades.json';
	var stateRes = $.get(stateEndpoint);
	stateField = $(stateField);

	stateRes.done(function (res) {
		res = JSON.parse(res);
		for (var i = 0; i < res.estados.length; i++) {
			stateField.append('<option value="' + res.estados[i].nome + '">' + res.estados[i].nome + '</option>');
		}
	});
}

/**
 *
 * basic menu functions
 *
*/

function toggleMenu() {
	$(this).toggleClass('is-active');
	$('.header-upper-menu').toggleClass('is-open');
	$('.close-submenu').removeClass('is-open');
}

function openSubMenu(e) {
	e.preventDefault();
	var closeBtn = $('.close-submenu');
	var childrenUL = $(this).children('ul');

	closeBtn.addClass('is-open');
	childrenUL.addClass('is-open');
}

function closeSubMenu() {
	$('.close-submenu').removeClass('is-open');
	$('.sub-menu.is-open').removeClass('is-open');
}

/**
 * @method maskInstancing
 * @uses jquery.mask.js <- on src/js/d.mask.js
 *
 * Basic form field masks
*/

function maskInstancing() {
	var SPMaskBehavior = function (val) {
		return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
	},
		spOptions = {
			onKeyPress: function (val, e, field, options) {
				field.mask(SPMaskBehavior.apply({}, arguments), options);
			}
		};

	$('input[name="Telefone"], input[name="Celular"], input[name="Telefone Fixo"]').mask(SPMaskBehavior, spOptions);
	$('input[name="Data"], input[name="Data de Nascimento"]').mask('00/00/0000');
	$('input[name="Horario"], input[name="Hora"]').mask('00:00');

}


/**
 *
 * @method clickToShow
 * @param string 		button function trigger
 * @param string 		hiddenElement | function target -> element that will be shown
 * @param bool			hideButton | Whether or not to hide the clicked element
 *
*/

function clickToShow(button, hiddenElement, hideButton) {
	$(button).on('click', function (e) {
		e.preventDefault();
		if (hideButton) {
			$(button).fadeOut(400, function () {
				$(hiddenElement).fadeIn();
			});
		} else {
			$(hiddenElement).fadeIn();
		}
	});
}

function openOverlay(e) {
	var target = $(this).data('overlay');

	if (target === '.promotion-modal-overlay') {
		helpers.lockBody();
	}

	if ($(this).hasClass('blue-basic')) {
		fillFieldInformation($(this));
	}

	if ($(this).is('a')) {
		e.preventDefault();
	}

	$(target).fadeIn();
}


/**
 *
 * Generic Overlay closure on click event
 *
 * @param e object Event
 *
*/

function closeOverlay(e) {
	$(this).parents('.overlay, .promotion-modal-overlay').fadeOut();
	helpers.unlockBody();

	if ($('#chosen').length == true) {
		clearFieldInformation('#chosen');
	}
}

/**
 *
 * Adds clicked promotion data into form
 * @param jqueryObject btnElement -> the button to be used for reference when gathering data
 * @see file includes/functions/actions.php -> mundi_promotions_output function
 * @usedby function openOverlay
 *
 *
*/

function fillFieldInformation(btnElement) {
	var content = btnElement.data('promotion');
	var id = btnElement.data('promotion-id');

	$('#cellphone').after(
		'<label class="field-wrapper full"><select class="blue-border rounded" name="Promoção Escolhida" id="chosen"><option selected value="' + id + ': ' + content + '">' + content + '</option></select></label>'
	);
}

/**
 *
 * @param string toClear element to clear
 * @usedby function closeOverlay
 * @see function fillFieldInformation
 *
 *
*/

function clearFieldInformation(toClear) {
	$(toClear).remove();
}


function makeAnchorsFixed() {
	if (window.pageYOffset > 220) {
		$('.anchor-listing').addClass('fixed');
	} else {
		$('.anchor-listing').removeClass('fixed');
	}
}

function anchorSmoothScroll(event) {
	if ($('.page-anchors li') === 0) {
		return false;
	}

	event.preventDefault();

	$('html, body').animate({
		scrollTop: $($.attr(this, 'href')).offset().top - 200
	}, 500);
}


/**
 *
 * @param {String} htmlContent | a string of html content to append
 * @param {jqueryObject} appendTarget | a target for appending.
 *
*/
const appendPromotions = (htmlContent, appendTarget, before) => {
	!!before ? $(appendTarget).before(htmlContent) : $(appendTarget).append(htmlContent)
}

/**
 *
 * @param {Array} posts | an array of post objects to build
 * @see {c.filters.js} | based on constructResponseBody method from Filters class
 * @uses appendPromotions | to bind the html response body
 *
*/

const constructPromotions = (posts) => {
	var HTMLcontent = '';

	posts.forEach(post => {
		var name = post.post_title;
		var thumbnail = post.thumbnail !== false ? post.thumbnail : helpers.hoisted.themeURL + '/images/common/placeholder-promotion.jpg';
		var price = post.price;
		var institutionName = post.institution_name;
		var institutionPermalink = post.institution_permalink;
		var content = post.post_content;
		var ID = post.ID;

		HTMLcontent +=
			`<div class="promotion-body">
			<img src="${thumbnail}" alt="${name}" class="promotion-image" />
			<div class="lines" aria-hidden="true"></div>
			<div class="content">
				<h3 class="promotion-name">${name}</h3>
				<h4 class="school-name"><a href="${institutionPermalink}">${institutionName}</a></h4>
				<p class="t-xs t-white lh-s promotion-description">${content}</p>
				<h5 class="promotion-pricing">R<span>S</span>${price}</h5>
				<button class="blue-basic open-overlay" data-overlay=".overlay" data-promotion-id="${ID}" data-promotion="${ID}: ${content}">
					ORÇAMENTO FÁCIL
				</button>
			</div>
		</div>`
	});

	return HTMLcontent;
}

/**
 * @see {actions.php} | mundi_hoist_promotion_query()
 * @see {actions.php} | mundi_promotions_output()
 * @returns {Void}
 * @since 14/10/2019
 *
*/

var offset = 0 // We remove one item because the button is also a child of '.prom-grid-basic'

const loadPromotions = (event) => {
	const currentQuery = JSON.parse(window.currentQuery); // passed by wp_localize_script on actions.php.
	const actionName = 'mundi_load_more_promotions';
	offset += 8

	showOverlay('.prom-grid-basic');

	// object setup
	currentQuery.offset = offset;

	let postData = {
		action: actionName,
		currentQuery: currentQuery
	}

	// request start
	let request = $.ajax({
		type: 'POST',
		url: helpers.hoisted.adminURL,
		data: postData,
	});

	currentQuery.offset = offset

	request.done(res => {
		res = JSON.parse(res);
		var contentToAppend = '';

		if (res.results.length > 0) {
			var promotionHTMLBody = constructPromotions(res.results);
			contentToAppend = promotionHTMLBody;
		} else {
			$(event.target).fadeOut();
			contentToAppend = `
			<a class="yellow-huge variant" href="//${window.location.origin}/orcamento-online">
				NÃO ENCONTROU O PACOTE DE INTERCÂMBIO QUE PROCURAVA?
				CLIQUE AQUI
			</a>
			`;
		}

		appendPromotions(contentToAppend, '.load-more-items', true);
		hideOverlay('.prom-grid-basic');
	});

}

/**
 * @param {String} targetElement | a dom element for jQuery to select
 *
 * Puts loader overlay on div while waiting for ajax calls.
*/

function showOverlay(targetElement) {
	target = $(targetElement);
	target.addClass('is-loading').append('<div class="ajax-overlay" aria-hidden="true">' + '<div class="lds-css ng-scope"><div style="width:100%;height:100%" class="lds-rolling"><div></div></div>' + '</div>');
}

/**
 * Hides overlays.
 *
*/

function hideOverlay() {
	$('.ajax-overlay').fadeOut(400, function () {
		$('.ajax-overlay').remove();
	});
	$('.is-loading').removeClass('is-loading');
}

/**
 *  Cookie related stuff
 * @param {String} name
 * @param {String} value
 * @param {Int} days
 */

function setCookie(name, value, days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

function eraseCookie(name) {
	document.cookie = name + '=; Max-Age=-99999999;';
}

function promotionalModal() {
	let now = new Date();
	let day = now.getDate();

	if (getCookie('modal') === null && !helpers.isMobile) {
		window.setTimeout(function () {
			helpers.lockBody();
			$('.black-friday-overlay').fadeIn();
			setCookie('modal', '', 2);
		}, 2000)
	}
}

function modal18() {
	let modal = document.querySelector('.modal-18-anos')

	if (!getCookie('modal18') && !helpers.isMobile && window.location.href === helpers.hoisted.homeURL) {
		setTimeout(() => {
			setCookie('modal18', 'open', 2)
			helpers.lockBody()
			modal.classList.add('open')
		}, 10000)
	}

	if ( document.querySelector('.dezoitoanos') !== null) {


		document.querySelector('.dezoitoanos')
		.addEventListener('click', e => {
			if (!helpers.isMobile) {
				e.preventDefault()
				helpers.lockBody()
				modal.classList.add('open')
			}
		})
	}

	if ( document.querySelector('.modal-18-anos .fa-times') !== null ) {
		document.querySelector('.modal-18-anos .fa-times')
		.addEventListener('click', e => {
			modal.classList.remove('open')
			helpers.unlockBody()
		})
	}
}

// function showEadModal() {
// 	if ( !getCookie('modalEad') ) {
// 		setTimeout(() => {
// 			setCookie('modalEad', 'open', 2)
// 			helpers.lockBody()
// 			$('.ead-overlay').fadeIn();
// 		}, 10000)
// 	}
// }