
/**
 * @constructor
 *
 * Class to implement filter rules on taxonomy and template pages
*/

function Filters() {

	this.settings = {
		ajaxURL: helpers.hoisted.adminURL,
		parentSection: $('.filter-offers'),
		parentContainer: $('.filter-offers').children('.center-content'),
		parentGrid: $('.prom-grid-basic'),
		selectElements: $('.filter-topic'),
		firstSelect: $('.language-filter'),
		secondSelect: $('.time-filter'),
		thirdSelect: $('.type-filter'),
		spinnerHTML: '<div class="lds-css ng-scope"><div style="width:100%;height:100%" class="lds-rolling"><div></div></div>',
		firstFilter: false,
        responseCount: null,
        debugResponse: false,
	}

	this.init();
}

Filters.prototype.init = function () {
	var self = this;

    // bind change event to second select
    // this is the language filter
    this.addEvent(this.settings.firstSelect, 'change', function () {
    	self.showOverlay();
    	self.handleSelectChanges($(this));

        // set value on settings
        self.settings.firstFilter = true;
        self.settings.language = self.settings.firstSelect.val();

        var langObject = {
        	language: self.settings.language,
        };

        var language = self.makeAjaxCall('POST', langObject, 'mundi_handle_filters');

        language.done(function (res) {
            if ( self.settings.debugResponse ) {
                self.debugResponse(res);
            } else {
                self.constructResponseBody(res);
                self.hideOverlay();
            }
        });

    });

    // enables third select
    // this is the travel time filter
    this.addEvent(this.settings.secondSelect, 'change', function () {
    	self.showOverlay();
    	self.handleSelectChanges($(this));


        // set travelTime value on settings
        self.settings.travelTime = self.settings.secondSelect.val();

        var timeObj = {
        	language: self.settings.language,
        	travel_time: self.settings.travelTime,
        }

        var travelTime = self.makeAjaxCall('POST', timeObj, 'mundi_handle_filters');

        travelTime.done(function (res) {
            self.constructResponseBody(res);
            self.hideOverlay();
        });
    });

    // set Corrent number of promotions
    this.printNumbers();

}


/**
 *
 * Constructs the response body
 */

Filters.prototype.constructResponseBody = function (obj) {

    var response, posts;
    var self = this;

    // parse received object
    if (typeof obj === 'string') {
        response = JSON.parse(obj)
        posts = JSON.parse(response.results);
    }

    // update numbers on result bar
    self.updateNumbers(response.extra_params.response_count);

    // empty the grid
    self.settings.parentGrid.empty();

    // loop and append html
    for (var i = 0; i < posts.length; i++) {

        var current = posts[i];

        var name = current.post_title;
        var thumbnail = current.thumbnail !== false ? current.thumbnail : helpers.hoisted.themeURL + '/images/common/placeholder-promotion.jpg';
        var price = current.price;
        var institutionName = current.institution_name;
        var institutionPermalink = current.institution_permalink;
        var content = current.post_content;
        var ID = current.ID;

        var body =
        '<div class="promotion-body">' +
            '<img src="' + thumbnail + '" alt="' + name + '" class="promotion-image">' + // thumbnail //
            '<div class="lines" aria-hidden="true"></div>' + // line props //
            '<div class="content">' + // content wrapper //
            '<h3 class="promotion-name">' + name + '</h3>' + // title //
            '<h4 class="school-name"><a href="'+institutionPermalink+'" title="'+institutionName+'">'+institutionName+'</a></h4>' + // Institution name and permalink //
            '<p class="t-s t-white lh-r promotion-description">' + content + '</p>' + // actual content //
            '<h5 class="promotion-pricing">R<span>S</span> ' + price + '</h5>' + // pricing //
            '<button class="blue-basic open-overlay" data-overlay=".overlay" data-promotion-id="'+ID+'" data-promotion="'+ID+': '+content+'">ORÇAMENTO FÁCIL</button>' +
            '</div>' // end .content
        '</div>' // end .promotion-body

        self.settings.parentGrid.append(body)

    }

}
/**
 * Helper method to attach events
 *
 * @param {jQueryObject} element | Element to bind event
 * @param {string} event | Event to Attach
 * @param {function} function | Function to attach
 *
*/

Filters.prototype.addEvent = function (element, event, func) {

 	if (typeof element !== 'object') {
        console.error('element must be a jQuery object');
        return false;
     }

 	return element.on(event, func);
}

Filters.prototype.updateNumbers = function(value) {
    $('#currently-showing').text(value);
}

/**
 * First number of promotions on page load.
 *
 */

Filters.prototype.printNumbers = function() {
    var num = $('.promotion-body').length;
    this.updateNumbers(num);
}

/*
 *
 *	helper method to standardize $.ajax calls
 *
 */

Filters.prototype.makeAjaxCall = function (method, data, action) {
var self = this;

data.action = action;

return $.ajax({
    type: method,
    url: self.settings.ajaxURL,
    data: data,
});
}

/*
*
*	Logical test for enabling selects on page
*
*/

Filters.prototype.handleSelectChanges = function (element) {
	if (element.hasClass('language-filter')) {

		this.enableSelect(this.settings.secondSelect);

	} else if (element.hasClass('time-filter')) {

		this.enableSelect(this.settings.thirdSelect);

	} else if (element.hasClass('type-filter')) {

        // deixei esse handler para a possibilidade de fazer mais algo aqui

    } else {
    	console.error('O case não se encaixou em nenhuma das situações previstas, dê uma olhada no método handleSelectChanges da classe Filters');
    }
}

/*
*
* Helper method to enable select elements
*
*/

Filters.prototype.enableSelect = function (element) {
	element.parents('.disabled-at-start').removeClass('disabled-at-start');
	element.prop('disabled', false);
}

/*
*
*	Show overlay when ajax starts
*
*/

Filters.prototype.showOverlay = function (targetElement) {
	var self = this;
	var target;

	if (arguments.length < 1) {
		target = this.settings.parentContainer;
	} else {
		target = $(targetElement);
	}

	target
	.addClass('is-loading')
	.append('<div class="ajax-overlay" aria-hidden="true">' + self.settings.spinnerHTML + '</div>');
}

/*
*
* Hide Overlay when ajax is Done
*
*/

Filters.prototype.hideOverlay = function () {
	$('.ajax-overlay').fadeOut(400, function () {
		$('.ajax-overlay').remove();
	});
	$('.is-loading').removeClass('is-loading');
}

Filters.prototype.debugResponse = function(response) {
    console.log(response);
}


Filters.prototype.test = function() {
    console.log('foo');
}